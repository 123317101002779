<template>
  <el-form :model="form" :rules="rules" label-position="top" style="max-width: 400px;margin-top: 5px;">
    <el-form-item label="昵称" prop="userName">
      <el-input v-model="form.username" placeholder="填写姓名" />
    </el-form-item>
    <el-form-item label="密码" style="margin-top: 5px;" prop="password">
      <el-input type="password" v-model="form.password" placeholder="填写密码" />
    </el-form-item>
    <el-form-item label="重复密码" style="margin-top: 5px;" prop="userPassword">
      <el-input type="password" v-model="form.repeatpassword" placeholder="确定密码" />
    </el-form-item>
    <el-form-item label="真实姓名" style="margin-top: 5px;" prop="realName">
      <el-input v-model="form.realName" placeholder="填写真实姓名" />
    </el-form-item>
    <el-form-item style="margin-top: 40px;">
      <el-button type="primary" @click="submitForm()">
        提交
      </el-button>
      <el-button @click="resetForm()">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ref,reactive } from 'vue'
import { register } from '../api/function'
const form = ref({
  username: '',
  password: '',
  repeatpassword: '',
  realName: ''
})

const submitForm = () => {
  // 处理表单提交逻辑
  console.log('Form submitted:', form.value)
  const data = {
    username: form.username,
    password: form.password,
    realName: form.realName
  }
  register(data).then(res => {
    console.log(res)
  })
}

const checkUserName = (rule, value, callback) => {
  // 初步检验格式
  if (form.value.username == '') {
    return callback(new Error('请填写姓名'))
  }
}

// 检验第一次输入的密码
const checkPwd = (rule,value,callback) => {
  if(form.value.password == ''){
    callback(new Error('请输入密码!'))
  }
}

// 检验重复输入的密码
const checkUserPwd = (rule, value, callback) => {
  if (form.value.repeatpassword == '') {
    callback(new Error('请输入密码!'))
  } else if(form.value.password != form.value.repeatpassword){
    callback(new Error('两次密码不一致！')) 
  }else{
    callback();
  }
}

const check = (rule,value,callback) => {
  if(form.value.realName == ''){
    return callback(new Error('真实姓名还未填写！')) 
  }
}

const rules = reactive({
  userName: [{ validator: checkUserName, trigger: 'blur' }],
  password: [{validator: checkPwd, trigger: 'blur'}],
  userPassword: [{ validator: checkUserPwd, trigger: 'blur' }],
  realName: [{validator: check,trigger:'blur'}]
})

</script>

<style>
</style>