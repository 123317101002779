import { getStudentInfo } from '../api/student'
import { getUser } from "@/api/function";

export class Student {
  constructor(id) {
    this.id = id;
    this.status = "student";
  }

// 在这里调用接口函数
  async login(){
    return getStudentInfo().then((res) => {
      this.id = res.data.id;
      this.username = res.data.username;
      this.realname = res.data.realName;
      this.age = res.data.age;
      this.gender = res.data.gender;
      this.sclass = res.data.sclass;
      this.school = res.data.school;
      this.phone = res.data.phone;
      return this;
    })
  }

  upUser(dataForm){
    this.id = dataForm.id;
    this.username = dataForm.username;
    this.realname = dataForm.realname;
    this.age = dataForm.age;
    this.gender = dataForm.gender;
    this.sclass = dataForm.sclass;
    this.school = dataForm.school;
    this.phone = dataForm.phone; 
    return this;
  }
}
export default Student;
