<template>
    <el-container class="container">
        <el-menu :default-active="activeIndex" ref="menuRef" id="container-menu" mode="horizontal" :ellipsis="false"
            @select="handleSelect">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">竞赛报名</el-menu-item>
            <el-menu-item index="3">课程预约</el-menu-item>
            <el-menu-item index="4">关于我们</el-menu-item>
            <div class="header">
                <!-- 设置class没有效果，可能是因为优先级不够，直接内嵌样式 -->
                <el-input placeholder="请输入您需要查找的赛事/课程/名师" style="width: 100%;padding-top: 10px;" size="large">
                    <template #append>
                        <el-button :icon="Search" />
                    </template>
                </el-input>
                <el-dropdown v-if="!loginStatus" style="position: absolute;margin: 25px 5% 23px 10%;">
                    <el-button link style="white-space: nowrap;font-size: medium;"
                        @click="logindialogVisible = true">未登录
                    </el-button>
                </el-dropdown>
                <el-dropdown v-if="loginStatus" style="position: absolute;margin: 25px 5% 23px 10%;"
                    @command="handleCommand">
                    <el-button link style="white-space: nowrap;font-size: medium;">{{ loginIdentity }}</el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="toPersonnel">个人主页</el-dropdown-item>
                            <el-dropdown-item @click="loginOut">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-button v-if="loginIdentity == 'admin'" link
                    style="position: absolute;margin: 25px 0 23px 16%;font-size: medium;" @click="manner()">管理员窗口
                </el-button>
            </div>
        </el-menu>
        <div class="medium">
            <el-carousel :interval="4000" type="card" height="500px" style="margin-top: 50px;">
                <el-carousel-item v-for="(image, index) in imgData" :key="index">
                    <img :src="image" style="height: 100%; width: 100%; object-fit: cover;" />
                </el-carousel-item>
            </el-carousel>
            <div class="bottom" ref="box">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="热门课程" name="first">
                        <course></course>
                    </el-tab-pane>
                    <el-tab-pane label="热门竞赛" name="second">
                        <competition></competition>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-dialog v-model="logindialogVisible" width="830" :before-close="handleClose" align-center :show-close="true">
            <el-container style="padding: 3% 5%; height: 550px; width: 800px; display: flex; background-color: white;">
                <img src="../img/login.jpg" style="width: 350px; height: 480px; object-fit: cover;">
                <el-tabs type="border-card" style="width: 400px; height: 478px; margin: 0;">
                    <el-tab-pane label="登录">
                        <Login />
                    </el-tab-pane>
                    <el-tab-pane label="注册">
                        <Register />
                    </el-tab-pane>
                </el-tabs>
            </el-container>
        </el-dialog>
        <div class="floating-icon">
            <el-menu class="el-menu-vertical-demo" :collapse="isCollapse"
                style="margin: 0px 0px 0px 10px;width: 70px;background-color: #7c91ac;">
                <el-sub-menu index="2" style="border: 1px solid gray;border-right: 1px solid white; margin-top: 10px;background-color: white;">
                    <template #title>
                        <el-icon @click="chatDemo()" :size="50">
                            <ChatDotRound />
                        </el-icon>
                    </template>
                </el-sub-menu>
            </el-menu>
        </div>
        <el-dialog v-model="mannerDialog" title="管理员登录" width="500" align-center>
            <el-form :model="form" label-width="auto" style="max-width: 450px">
                <el-form-item label="token">
                    <el-input style="width: 100%;" v-model="form.token" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="mannerDialog = false">取消</el-button>
                    <el-button type="primary" @click="loginManner()">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="centerDialog" title="管理员界面" width="1150" style="height: 650px;overflow-y: auto;" align-center>
            <div class="centerDiv">
                <el-tabs tab-position="top" v-model="AllTabsValue" @tab-click="handleClickAll()"
                    style="height: 500px;overflow-y: auto;margin-top: 10px;" class="demo-tabs">
                    <el-tab-pane label="实时资讯">
                        <el-table :data="matData" style="width: 100%">
                            <el-table-column prop="id" label="资讯ID" width="100" />
                            <el-table-column prop="title" label="资讯名称" width="150" />
                            <el-table-column prop="content" label="资讯介绍" width="200" />
                            <el-table-column prop="publishDate" label="发布时间" width="180" />
                            <el-table-column prop="image" label="图片路径" width="250">
                                <template #default="scope">
                                    <el-image :src="scope.row.image" />
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="150">
                                <template #default="scope">
                                    <el-button size="small" @click="mateEdit(scope.$index, scope.row)">
                                        修改
                                    </el-button>
                                    <el-button size="small" type="danger" @click="mateDelete(scope.$index, scope.row)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="所有用户">
                        <el-table :data="userData" style="width: 100%">
                            <el-table-column prop="id" label="用户ID" width="100" />
                            <el-table-column prop="username" label="用户名称" width="150" />
                            <el-table-column prop="realName" label="真实名称" width="100" />
                            <el-table-column prop="phone" label="手机号码" width="150" />
                            <el-table-column prop="gender" label="性别" width="100" />
                            <el-table-column prop="age" label="年龄" width="100" />
                            <el-table-column prop="password" label="密码" width="300">
                                <template #default="scope">
                                    {{ displayPassword(scope.row.password) }}
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="150">
                                <template #default="scope">
                                    <el-button size="small" @click="userEdit(scope.$index, scope.row)">
                                        修改
                                    </el-button>
                                    <el-button size="small" type="danger" @click="userDelete(scope.$index, scope.row)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="所有课程">
                        <el-table :data="courData" style="width: 100%">
                            <el-table-column prop="id" label="课程ID" width="100" />
                            <el-table-column prop="courseName" label="课程名称" width="100" />
                            <el-table-column prop="teacherName" label="任课教师" width="100" />
                            <el-table-column prop="introduction" label="课程介绍" width="200" />
                            <el-table-column prop="startDate" label="开始时间" width="180" />
                            <el-table-column prop="endDate" label="结束时间" width="180" />
                            <el-table-column prop="image" label="图片路径" width="250">
                                <template #default="scope">
                                    <el-image :src="scope.row.image" />
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="150">
                                <template #default="scope">
                                    <el-button size="small" @click="courEdit(scope.$index, scope.row)">
                                        修改
                                    </el-button>
                                    <el-button size="small" type="danger" @click="courDelete(scope.$index, scope.row)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="所有竞赛">
                        <el-table :data="comData" style="width: 100%;height: 100%;">
                            <el-table-column prop="id" label="竞赛ID" width="100" />
                            <el-table-column prop="title" label="竞赛名称" width="150" />
                            <el-table-column prop="content" label="竞赛介绍" width="300" />
                            <el-table-column prop="publishDate" label="宣布时间" width="180" />
                            <el-table-column prop="image" label="图片路径" width="250">
                                <template #default="scope">
                                    <el-image :src="scope.row.image" />
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="150">
                                <template #default="scope">
                                    <el-button size="small" @click="comEdit(scope.$index, scope.row)">
                                        修改
                                    </el-button>
                                    <el-button size="small" type="danger" @click="comDelete(scope.$index, scope.row)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <el-dialog v-model="innerVisible" width="500" title="增加信息" append-to-body align-center>
                <el-tabs type="border-card" v-model="TabsValue" @tab-click="handleClick">
                    <el-tab-pane label="资讯" name="资讯">
                        <el-form :label-position="left" label-width="auto" :model="mateForm" style="max-width: 600px">
                            <el-form-item label="ID">
                                <el-input v-model="mateForm.id" />
                            </el-form-item>
                            <el-form-item label="资讯名称">
                                <el-input v-model="mateForm.title" />
                            </el-form-item>
                            <el-form-item label="资讯介绍">
                                <el-input v-model="mateForm.content" />
                            </el-form-item>
                            <el-form-item label="发布时间">
                                <el-date-picker v-model="mateForm.publishDate" type="datetime"
                                    placeholder="选择时间" />
                            </el-form-item>
                            <el-form-item label="资源类型">
                                <el-input v-model="mateForm.category" />
                            </el-form-item>
                            <el-form-item label="图片上传">
                                <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                                    action="http://159.75.72.12:8088/image/upload" multiple :limit="3"
                                    :on-success="uploadSuccess">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只能上传小于500KB的 jpg/png文件.
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-form>

                    </el-tab-pane>
                    <el-tab-pane label="用户" name="用户">
                        <el-form :label-position="left" label-width="auto" :model="userForm" style="max-width: 600px">
                            <el-form-item label="ID">
                                <el-input v-model="userForm.id" disabled />
                            </el-form-item>
                            <el-form-item label="昵称">
                                <el-input v-model="userForm.username" />
                            </el-form-item>
                            <el-form-item label="实名">
                                <el-input v-model="userForm.realName" />
                            </el-form-item>
                            <el-form-item label="年龄">
                                <el-input v-model="userForm.age" />
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-input v-model="userForm.gender" />
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input v-model="userForm.password" />
                            </el-form-item>
                            <el-form-item label="图片上传">
                                <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                                    action="http://159.75.72.12:8088/image/upload" multiple :limit="3"
                                    :on-success="uploadSuccess">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只能上传小于500KB的 jpg/png文件.
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="课程" name="课程">
                        <el-form :label-position="left" label-width="auto" :model="courForm" style="max-width: 600px">
                            <el-form-item label="ID">
                                <el-input v-model="courForm.id" disabled />
                            </el-form-item>
                            <el-form-item label="课程名称">
                                <el-input v-model="courForm.courseName" />
                            </el-form-item>
                            <el-form-item label="课程介绍">
                                <el-input v-model="courForm.introduction" />
                            </el-form-item>
                            <el-form-item label="任课老师">
                                <el-input v-model="courForm.teacherName" />
                            </el-form-item>
                            <el-form-item label="开始时间">
                                <el-date-picker v-model="courForm.startDate" type="datetime"
                                    placeholder="选择时间" />
                            </el-form-item>
                            <el-form-item label="结束时间">
                                <el-date-picker v-model="courForm.endDate" type="datetime"
                                    placeholder="选择时间" />
                            </el-form-item>
                            <el-form-item label="图片上传">
                                <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                                    action="http://159.75.72.12:8088/image/upload" multiple :limit="3"
                                    :on-success="uploadSuccess">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只能上传小于500KB的 jpg/png文件.
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="竞赛" name="竞赛">
                        <el-form :label-position="left" label-width="auto" :model="comForm" style="max-width: 600px">
                            <el-form-item label="ID">
                                <el-input v-model="comForm.id" disabled />
                            </el-form-item>
                            <el-form-item label="资讯名称">
                                <el-input v-model="comForm.title" />
                            </el-form-item>
                            <el-form-item label="资讯介绍">
                                <el-input v-model="comForm.content" />
                            </el-form-item>
                            <el-form-item label="发布时间">
                                <el-date-picker v-model="comForm.publishDate" type="datetime"
                                    placeholder="选择时间" />
                            </el-form-item>
                            <el-form-item label="资源类型">
                                <el-input v-model="comForm.category" />
                            </el-form-item>
                            <el-form-item label="图片上传">
                                <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                                    action="http://159.75.72.12:8088/image/upload" multiple :limit="3"
                                    :on-success="uploadSuccess">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只能上传小于500KB的 jpg/png文件.
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
                <template #footer>
                    <div class="dialogFooter">
                        <el-button @click="innerVisible = false">退出界面</el-button>
                        <el-button type="primary" @click="confime()">
                            确定
                        </el-button>
                    </div>
                </template>
            </el-dialog>
            <template #footer>
                <div class="dialogFooter">
                    <el-button @click="innerVisible = true">增加信息</el-button>
                    <el-button type="primary" @click="centerDialog = false">
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="mateDialog" title="编辑资讯信息" width="500" align-center>
            <el-form :label-position="left" label-width="auto" :model="mateForm" style="max-width: 600px">
                <el-form-item label="ID">
                    <el-input v-model="mateForm.id" disabled />
                </el-form-item>
                <el-form-item label="资讯名称">
                    <el-input v-model="mateForm.title" />
                </el-form-item>
                <el-form-item label="资讯介绍">
                    <el-input v-model="mateForm.content" />
                </el-form-item>
                <el-form-item label="发布时间">
                    <el-input v-model="mateForm.publishDate" />
                </el-form-item>
                <el-form-item label="资源类型">
                    <el-input v-model="mateForm.category" />
                </el-form-item>
                <el-form-item label="图片上传">
                    <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                        action="http://159.75.72.12:8088/image/upload" multiple :limit="3" :on-success="uploadSuccess">
                        <el-button type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                                只能上传小于500KB的 jpg/png文件.
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="mateDialog = false">取消</el-button>
                    <el-button type="primary" @click="mateConfimeUp()">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="userDialog" title="编辑用户信息" width="500" align-center>
            <el-form :label-position="left" label-width="auto" :model="userForm" style="max-width: 600px">
                <el-form-item label="ID">
                    <el-input v-model="userForm.id" disabled />
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="userForm.username" />
                </el-form-item>
                <el-form-item label="实名">
                    <el-input v-model="userForm.realName" />
                </el-form-item>
                <el-form-item label="年龄">
                    <el-input v-model="userForm.age" />
                </el-form-item>
                <el-form-item label="性别">
                    <el-input v-model="userForm.gender" />
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="userForm.password" />
                </el-form-item>
                <el-form-item label="图片上传">
                    <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                        action="http://159.75.72.12:8088/image/upload" multiple :limit="3" :on-success="uploadSuccess">
                        <el-button type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                                只能上传小于500KB的 jpg/png文件.
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="userDialog = false">取消</el-button>
                    <el-button type="primary" @click="userConfimeUp()">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="courDialog" title="编辑课程信息" width="500" align-center>
            <el-form :label-position="left" label-width="auto" :model="courForm" style="max-width: 600px">
                <el-form-item label="ID">
                    <el-input v-model="courForm.id" disabled />
                </el-form-item>
                <el-form-item label="课程名称">
                    <el-input v-model="courForm.courseName" />
                </el-form-item>
                <el-form-item label="课程介绍">
                    <el-input v-model="courForm.introduction" />
                </el-form-item>
                <el-form-item label="任课老师">
                    <el-input v-model="courForm.teacherName" />
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-input v-model="courForm.startDate" />
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-input v-model="courForm.endDate" />
                </el-form-item>
                <el-form-item label="图片上传">
                    <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                        action="http://159.75.72.12:8088/image/upload" multiple :limit="3" :on-success="uploadSuccess">
                        <el-button type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                                只能上传小于500KB的 jpg/png文件.
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="courDialog = false">取消</el-button>
                    <el-button type="primary" @click="courConfimeUp()">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="comDialog" title="编辑竞赛信息" width="500" align-center>
            <el-form :label-position="left" label-width="auto" :model="comForm" style="max-width: 600px">
                <el-form-item label="ID">
                    <el-input v-model="comForm.id" disabled />
                </el-form-item>
                <el-form-item label="资讯名称">
                    <el-input v-model="comForm.title" />
                </el-form-item>
                <el-form-item label="资讯介绍">
                    <el-input v-model="comForm.content" />
                </el-form-item>
                <el-form-item label="发布时间">
                    <el-input v-model="comForm.publishDate" />
                </el-form-item>
                <el-form-item label="资源类型">
                    <el-input v-model="comForm.category" />
                </el-form-item>
                <el-form-item label="图片上传">
                    <el-upload v-model:file-list="fileList" :headers="headers" class="upload-demo"
                        action="http://159.75.72.12:8088/image/upload" multiple :limit="3" :on-success="uploadSuccess">
                        <el-button type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                                只能上传小于500KB的 jpg/png文件.
                            </div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="comDialog = false">取消</el-button>
                    <el-button type="primary" @click="comConfimeUp()">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog v-model="chatcenterDialog" title="聊天界面" width="1500" style="height: 700px;" align-center>
            <Im></Im>
        </el-dialog>
    </el-container>
</template>
<script setup>
import { gsap } from "gsap";
import { ref, onMounted, computed, watch } from 'vue'
import { Search, ArrowRight, ArrowLeft, Service, ChatDotRound } from '@element-plus/icons-vue'
import Draggable from "gsap/Draggable"
import ScrollTrigger from "gsap/ScrollTrigger";
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import course from '../components/course.vue'
import competition from '../components/competition.vue'
import Login from '../components/login.vue'
import Register from "../components/register.vue"
import Im from "../views/Im.vue"

import { useTokenStore } from '@/stores/token.js'
import { solution, getCompetition, getCourse, getAllUser, addAllDate, upAllDate, delAllDate } from "../api/function"

const innerVisible = ref(false);

import { useStore } from 'vuex';

const store = useStore();

const activeIndex = ref('1');

const comData = ref([])
const comDialog = ref(false)
const matData = ref([])
const mateDialog = ref(false)
const userData = ref([])
const userDialog = ref(false)
const courData = ref([])
const courDialog = ref(false)

const displayPassword = (password) => '*'.repeat(password.length);
onMounted(() => {
    Draggable.create(".bottom", {
        // 当 minY 是负数时，表示元素可以向上拖拽的最远距离。maxY: 表示元素在 Y 轴上的最大允许位置。
        bounds: { minY: -610, maxY: 80 },
        edgeResistance: 0.9,
        type: "y",
        onDragEnd: function () {
            let currentY = this.y;
            if (currentY <= -345) {
                // 修改菜单项为第二项
                activeIndex.value = '2'; // 修改选中的菜单项为第二项
            } else {
                activeIndex.value = '1';
            }
        }
    });
    refreshData();
});

const refreshData = () => {
    solution().then((res) => {
        console.log("资讯");
        console.log(res.data);
        matData.value = res.data;
    })
    getCompetition().then((res) => {
        comData.value = res.data;
    })
    getCourse().then((res) => {
        courData.value = res.data;
    })
    getAllUser().then((res) => {
        userData.value = res.data;
    })
}

const fileList = ref([]);
const tokenStore = useTokenStore();
const imgURl = ref();
// 上传成功的回调
const uploadSuccess = (res, upload) => {
    imgURl.value = res.data;
    console.log(TabsValue.value);
    if(TabsValue.value == '资讯'){
        mateForm.value.image = res.data;
    }else if(TabsValue.value == '资讯'){
        mateForm.value.image = res.data;
    }else if(TabsValue.value == '资讯'){
        mateForm.value.image = res.data;
    }else if(TabsValue.value == '资讯'){
        mateForm.value.image = res.data;
    }

    console.log(imgURl.value);
}


// 默认选中课程
const activeName = ref('first')

const form = ref({
    token: ""
})
const handleClickAll = () => {

}
// 资讯表单
const mateForm = ref({
    id: '',
    title: '',
    content: '',
    publishDate: '',
    category: 'StudyMaterial',
    image:imgURl.value
})
const mateEdit = (index, row) => {
    mateForm.value = row;
    mateDialog.value = true;
}
const mateConfimeUp = () => { // 资讯修改
    upAllDate(mateForm.value, AllTabsValue.value).then((res) => {
        if (res.code == 200) {
            ElMessage({
                message: '更新成功',
                type: 'success'
            })
            refreshData();
            mateDialog.value = false;
        }
    })
}
const mateDelete = (index, row) => { // 资讯删除
    delAllDate(row.id, AllTabsValue.value).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success'
        })
        refreshData();
    })
}
const chatcenterDialog = ref(false);
// 聊天界面
const chatDemo = () => {
    chatcenterDialog.value = true;
}

// 用户表单
const userForm = ref({
    id: '',
    username: '',
    realName: '',
    age: '',
    gender: '',
    password: ''
})
const userEdit = (index, row) => {
    userForm.value = row;
    userDialog.value = true;
}
const userConfimeUp = () => { // 用户修改
    upAllDate(userForm.value, AllTabsValue.value).then((res) => {
        if (res.code == 200) {
            ElMessage({
                message: '更新成功',
                type: 'success'
            })
            refreshData();
            userDialog.value = false;
        }
    })
}
const userDelete = (index, row) => { // 用户删除
    delAllDate(row.id, AllTabsValue.value).then((res) => {
        if (res.code == 200) {
            ElMessage({
                message: '删除成功',
                type: 'success'
            })
            refreshData();
        }
    })
}

// 课程表单
const courForm = ref({
    id: '',
    courseName: '',
    introduction: '',
    teacherName: '',
    startData: '',
    endDate: '',
    image:imgURl.value
})
const courEdit = (index, row) => {
    courForm.value = row;
    courDialog.value = true;
}
const courConfimeUp = () => { // 课程修改
    upAllDate(courForm.value, AllTabsValue.value).then((res) => {
        if (res.code == 200) {
            ElMessage({
                message: '更新成功',
                type: 'success'
            })
            refreshData();
            courDialog.value = false;
        }
    })
}
const courDelete = (index, row) => { // 课程删除
    delAllDate(row.id, AllTabsValue.value).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success'
        })
        refreshData();
    })
}

// 竞赛表单
const comForm = ref({
    id: '',
    title: '',
    content: '',
    publishDate: '',
    category: 'Competition',
    image:imgURl.value
})
const comEdit = (index, row) => {
    comForm.value = row;
    comDialog.value = true;
}
const comConfimeUp = () => { // 竞赛修改
    upAllDate(comForm.value, AllTabsValue.value).then((res) => {
        if (res.code == 200) {
            ElMessage({
                message: '更新成功',
                type: 'success'
            })
            refreshData();
            comDialog.value = false;
        }
    })
}
const comDelete = (index, row) => { // 竞赛删除
    delAllDate(row.id, AllTabsValue.value).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success'
        })
        refreshData();
    })
}

const TabsValue = ref("资讯");
const AllTabsValue = ref("实时资讯")

const router = useRouter()

const loginStatus = computed(() => store.state.isLogin);
const loginIdentity = computed(() => store.state.user ? store.state.user.status : '');

const handleSelect = (index) => {
    if (index == 1) {
        window.scrollTo(0, 0);
        gsap.to(".bottom", { duration: 0.9, y: 40 });
    } else if (index == 2 || index == 3) {
        // 移动到 -minY 的位置
        gsap.to(".bottom", { duration: 0.5, y: -610 });
    } else if (index == 4) {
        router.push('/ours');
    }
}

// 增加信息模块中，根据模块判断传递的信息和调用的接口
const confime = () => {
    if (TabsValue.value == '资讯') {
        console.log(mateForm.value);
        addAllDate(mateForm.value, TabsValue.value).then((res) => {
            ElMessage({
                message: '添加成功',
                type: 'success'
            })
            refreshData();
        })
    } else if (TabsValue.value == '用户') {
        addAllDate(userForm.value, TabsValue.value).then((res) => {
            ElMessage({
                message: '添加成功',
                type: 'success'
            })
            refreshData();
        })
    } else if (TabsValue.value == '课程') {
        addAllDate(courForm.value, TabsValue.value).then((res) => {
            ElMessage({
                message: '添加成功',
                type: 'success'
            })
            refreshData();
        })
    } else if (TabsValue.value == '竞赛') {
        addAllDate(comForm.value, TabsValue.value).then((res) => {
            ElMessage({
                message: '添加成功',
                type: 'success'
            })
            refreshData();
        })
    }
    innerVisible.value = false;
}

const imgData = ref([
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/fdec6274-68c2-48d5-ba15-eb51e3932f38.png',
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/5c34a82a-0fde-4a35-ba7a-6fb53de27921.png',
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/612801d9-96b0-44d9-a2d9-73af311b5f5c.png',
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/97cb669d-d181-4591-a76c-fdd86d76a921.png',
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/858be000-09fc-49dc-8b09-7834c49fc286.png',
    'https://xyx-storage.oss-cn-beijing.aliyuncs.com/73a431b5-b039-46c4-bcf4-3c07d5c11449.png'
])

gsap.registerPlugin(Draggable);
gsap.registerPlugin(ScrollTrigger);

const menuRef = ref(null); // 创建对菜单组件的引用
let logindialogVisible = ref(false);

const toPersonnel = () => {
    if (loginIdentity.value === 'student') {
        router.push('/student');
    } else if (loginIdentity.value === 'teacher') {
        router.push('/teacher');
    }
}

const handleCommand = (command) => {
    if (command === 'login') {
        logindialogVisible.value = true;
    }
}

const handleClose = () => {
    ElMessageBox.confirm('你确定要关闭弹窗吗?')
        .then(() => {
            logindialogVisible.value = false;
        })
}

const loginOut = () => {
    store.commit('upLoginStatus', false); // 假设服务器响应中 user 数据在 res.data.user 中
}

const handleLoginStatusChange = () => {
    if (loginStatus.value == true) {
        console.log('用户已登录');
        // 执行用户登录后的操作
        logindialogVisible.value = false;
    } else {
        console.log('用户未登录');
        // 执行用户登出后的操作
        loginOut()
    }
};


// 监听登录状态是否更新
watch(loginStatus, (newVal) => {
    handleLoginStatusChange(newVal);
}, { immediate: true });

gsap.timeline({
    scrollTrigger: {
        trigger: ".medium",
        // markers: true,
        end: "bottom-=310", // end after scrolling 500px beyond the start
        onLeave: () => {
            activeIndex.value = 2; // 修改选中的菜单项为第二项
        },
        onLeaveBack: () => {
            activeIndex.value = '1'; // 修改选中的菜单项为第一项
        }
    },
});

const mannerDialog = ref(false);
const centerDialog = ref(false);

const loginManner = () => {
    mannerDialog.value = false;
    centerDialog.value = true;
}

// 打开管理员窗口
const manner = () => {
    mannerDialog.value = true;
}
</script>

<style scoped>
#container-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 97%;
    height: 65px;
    z-index: 2000;
    margin: 0px 1.5%;
}

.el-menu-item:hover {
    background-color: transparent;
    /* 设置悬浮时背景色为透明 */
    color: inherit;
    /* 保持文字颜色不变 */
}

.container {
    position: relative;
    width: 100%;
}

.header {
    width: 40%;
    margin-left: 8%;
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.medium {
    margin-top: 50px;
    width: 97.6%;
    margin: 65px 1.2%;
}

.bottom {
    position: absolute;
    margin-top: 60px;
    width: 90%;
    height: 880px;
    z-index: 8;
    border: 1px black solid;
    padding: 1% 5% 0% 3%;
    background-color: white;
}

.centerDiv {
    height: 100%;
    width: 100%;
}

.dialogFooter {
    width: 100%;
    height: 60px;
}

.dialogFooter>>>.el-button {
    height: 40px;
    width: 100px;
    margin: 10px;
}

.floating-icon {
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 80px;
    height: 190px;
    bottom: 40px;
    /* 距离底部的距离 */
    right: 0px;
    /* 距离右侧的距离 */
    z-index: 2000;
    /* 确保图标显示在其他内容上方 */
}
</style>
