<template>
  <el-container class="container">
    <el-menu :default-active="activeIndex" ref="menuRef" style="
  position: fixed;
  top: 0;
  left: 0;
  width: 97%;
  height: 65px;
  z-index: 2000;
  margin: 0px 1.5%;
  padding: 0px 3%" mode="horizontal" :ellipsis="false" @select="handleSelect">
      <el-menu-item index="1">首页</el-menu-item>
      <el-menu-item index="2">个人主页</el-menu-item>
      <el-menu-item index="3">信息中心</el-menu-item>
      <div class="header">
        <!-- 设置class没有效果，可能是因为优先级不够，直接内嵌样式 -->
        <el-input placeholder="请输入您需要查找的赛事/课程/名师" style="width: 100%;padding-top: 10px;" size="large">
          <template #append>
            <el-button :icon="Search" />
          </template>
        </el-input>
        <el-dropdown style="position: absolute;margin: 25px 5% 23px 10%;">
          <el-text style="white-space: nowrap;font-size: medium;">{{ loginIdentity }}</el-text>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="personnel" @click="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-menu>
    <div class="medium">
      <img src="../img/login.jpg" height="320px" width="230px" style="margin: 25px 0px 25px 10px;" alt="">
      <div class="mine">
        <el-descriptions style="opacity: 0.85;" title="学生信息" :column="3" :size="large" border>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                ID
              </div>
            </template>
            {{ student.id }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                昵称
              </div>
            </template>
            {{ student.username }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                实名
              </div>
            </template>
            {{ student.realname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                手机号码
              </div>
            </template>
            {{ student.phone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                学校
              </div>
            </template>
            {{ student.school }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                年龄
              </div>
            </template>
            {{ student.age }} 
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                性别
              </div>
            </template>
            {{ student.gender }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                班级
              </div>
            </template>
            {{ student.sclass }}
          </el-descriptions-item>
        </el-descriptions>
        <el-button type="primary" style=" width: 150px; margin: 1.5% 75% 0% 0%;" plain @click="upStudent">编辑</el-button>
      </div>
    </div>
    <div class="bottom">
      <el-tabs type="border-card" class="demo-tabs">
        <el-tab-pane label="我的竞赛">
          <MinCom />
        </el-tab-pane>
        <el-tab-pane label="我的课程">
          <MinCourse />
        </el-tab-pane>
        <el-tab-pane label="学习记录">
          <blank></blank>
        </el-tab-pane>
        <el-tab-pane label="历史成绩">
          <blank></blank>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog v-model="studentDialog" title="编辑个人信息" width="500" align-center>
      <el-form :label-position="left" label-width="auto" :model="studentform" style="max-width: 600px">
        <el-form-item label="ID">
          <el-input v-model="studentform.id" disabled />
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="studentform.username" />
        </el-form-item>
        <el-form-item label="实名">
          <el-input v-model="studentform.realname" />
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="studentform.age" />
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="studentform.gender" />
        </el-form-item>
        <el-form-item label="学校">
          <el-input v-model="studentform.school" />
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model="studentform.sclass" />
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="studentform.phone" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="studentDialog = false">取消</el-button>
          <el-button type="primary" @click="confimeUp()">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </el-container>
</template>
<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { Search } from '@element-plus/icons-vue'
import blank from '../components/blank.vue'
import { useRouter } from 'vue-router'
import MinCom from "../components/mineCom.vue"
import MinCourse from "../components/mineCourse.vue"
import { upStudentInfo } from "../api/student"
import { logoutUser } from "../api/function"
import { ElMessage } from "element-plus";
import { useStore } from 'vuex';

const store = useStore();
// 创建一个 Student 实例
const student = computed(() => store.state.user);

const router = useRouter()

const activeName = ref('我的竞赛');
const studentDialog = ref(false);

const studentform = ref({
  id: '',
  username: '',
  realname: '',
  age: '',
  gender: '',
  phone: '',
  sclass: '',
  school: ''
})
const user = reactive({
  studentform: localStorage.getItem('user')
})


// 默认选择“个人主页”
const activeIndex = ref('2');
let loginIdentity = ref("student");

const handleSelect = (index) => {
  if (index == 1) {
    router.push('/');
  }
}

const upStudent = () => {
  studentform.value.id = student.value.id;
  studentform.value.username = student.value.username;
  studentform.value.realname = student.value.realname;
  studentform.value.age = student.value.age;
  studentform.value.gender = student.value.gender;
  studentform.value.phone = student.value.phone;
  studentform.value.sclass = student.value.sclass;
  studentform.value.school = student.value.school;
  studentDialog.value = true;
}

const confimeUp = () => {
  // 更新学生全部信息
  upStudentInfo(studentform.value).then((res) => {
    // 返回修改的状态
    if (res.code == 200) {
      ElMessage({
        message: '修改成功',
        type: 'success'
      });
      studentDialog.value = false;
      store.commit('updateUserInfo', studentform.value); // 假设服务器响应中 user 数据在 res.data.user 中 
      // 刷新页面
    } else {
      ElMessage({
        message: '修改失败',
        type: 'error'
      });
    }
  })
}

// 退出登录
const logout = () => {
  store.commit('upLoginStatus', false); // 假设服务器响应中 user 数据在 res.data.user 中 
  router.push('/');
}

const menuRef = ref(null); // 创建对菜单组件的引用


</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 40%;
  margin-left: 10%;
}

.medium {
  margin: 3.5% 2.5% 0 2.5%;
  width: 95%;
  height: 370px;
  background-color: #a7bfe8;
  display: flex;
  /* border: 1px solid #333; 边框宽度和颜色 */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 阴影效果 */
}

.bottom {
  margin: 0 2.5% 2.5% 2.5%;
  width: 95%;
  background-color: #7b9edc;
  height: 450px;
}

::v-deep .el-tabs {
  /* background-color: antiquewhite;*/
  width: 100%;
  height: 350px;
}

.blank {
  height: 280px;
  align-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  background-color: white;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); 设置阴影效果 */
}

.demo-tabs {
  height: 450px;
}

.mine {
  width: 75%;
  height: 320px;
  // flex-direction: column;
  // /* 设置主轴方向为垂直 */
  // display: flex;
  // justify-content: flex-start;
  /* 设置子元素在主轴方向上居左对齐 */
  margin: 25px;
}

.mine text {
  margin: 0.7%;
  font-size: 17px;
}
</style>
