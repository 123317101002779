<template>
  <el-table :data="tableData" style="width: 100%;height: 350px;overflow-y: auto;" >
    <el-table-column prop="name" label="课程" width="180" />
    <el-table-column prop="teacher" label="任课老师" width="180" />
    <el-table-column prop="stime" label="开始时间" width="250" />
    <el-table-column prop="etime" label="结束时间" width="250"/>
    <el-table-column prop="details" label="详情"  /> 
  </el-table>
</template>

<script setup>
const tableData = [
{
    name: '高数',
    teacher: '张三',
    stime: '2012-12月-22号',
    etime:'2013-2月-18号',
    imgURL: require("@/img/login.jpg"),
    details: '高数入门基础教程，适合没有基础的初学者'
  },
  {
    name: '数学建模',
    teacher: '王武',
    stime: '2012-12月-22号',
    etime:'2013-2月-18号',
    imgURL: '',
    details: '数学建模高级教程，适合有一定基础的学生，初入门可以选选取出门教程进行学习'
  },
  
  
 
]
</script>
