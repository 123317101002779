<template>
  <el-container class="container">
      <el-menu
  :default-active="activeIndex"
  ref="menuRef"
  style="
  position: fixed;
  top: 0;
  left: 0;
  width: 97%;
  height: 65px;
  z-index: 2000;
  margin: 0px 1.5%;
  padding: 0px 3%"
  mode="horizontal"
  :ellipsis="false"
  @select="handleSelect"
>
<el-menu-item index="1">首页</el-menu-item>
<el-menu-item index="2">个人主页</el-menu-item>
<el-menu-item index="3">信息中心</el-menu-item>
  <div class="header">
    <!-- 设置class没有效果，可能是因为优先级不够，直接内嵌样式 -->
    <el-input
    placeholder="请输入您需要查找的赛事/课程/名师"
    style="width: 100%;padding-top: 10px;"
    size="large"
  >
  <template #append>
      <el-button :icon="Search" />
  </template>
</el-input>
<el-dropdown style="position: absolute;margin: 25px 5% 23px 10%;">
<el-text style="white-space: nowrap;font-size: medium;">{{ loginIdentity }}</el-text>
<template #dropdown>
  <el-dropdown-menu>
    <el-dropdown-item command="personnel" @click="logout">退出</el-dropdown-item>
  </el-dropdown-menu>
</template>
</el-dropdown>
  </div>
  </el-menu>
  <div class="medium">
    <img src="../img/login.jpg" height="320px" width="230px" style="margin: 25px 0px 25px 10px;" alt="">
    <div class="mine">
      <el-descriptions style="opacity: 0.85;" title="教师信息" :column="3" :size="large" border>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                ID
              </div>
            </template>
            {{ teacher.id }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                昵称
              </div>
            </template>
            {{ teacher.username }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                实名
              </div>
            </template>
            {{ teacher.realname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                手机号码
              </div>
            </template>
            {{ teacher.phone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                年龄
              </div>
            </template>
            {{ teacher.age }} 
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                性别
              </div>
            </template>
            {{ teacher.gender }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                获奖经历
              </div>
            </template>
            {{ teacher.teachingExperience }}
          </el-descriptions-item>
        </el-descriptions>
        <el-button type="primary" style=" width: 150px; margin: 1.5% 75% 0% 0%;" plain @click="upTeacher">编辑</el-button>
    </div>
  </div>
  <div class="bottom">
    <el-tabs v-model="activeName" type="border-card" class="demo-tabs">
    <el-tab-pane label="教学计划" name="教学计划" >
      <blank></blank>
    </el-tab-pane>
    <el-tab-pane label="我的班级">
      <blank></blank>
    </el-tab-pane>
  </el-tabs>
  </div>
  <el-dialog
    v-model="teacherDialog"
    title="编辑个人信息"
    width="500"
    align-center
  >
  <el-form
    :label-position="left"
    label-width="auto"
    :model="teacherform"
    style="max-width: 600px"
  >
    <el-form-item label="ID">
      <el-input v-model="teacherform.id" disabled/>
    </el-form-item>
    <el-form-item label="昵称">
      <el-input v-model="teacherform.username" />
    </el-form-item>
    <el-form-item label="实名">
      <el-input v-model="teacherform.realname" />
    </el-form-item>
    <el-form-item label="年龄">
      <el-input v-model="teacherform.age" />
    </el-form-item>
    <el-form-item label="性别">
      <el-input v-model="teacherform.gender" />
    </el-form-item>
    <el-form-item label="手机号码">
      <el-input v-model="teacherform.phone" />
    </el-form-item>
    <el-form-item label="获奖经历">
      <el-input v-model="teacherform.teachingExperience" />
    </el-form-item>
  </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="studentDialog = false">取消</el-button>
        <el-button type="primary" @click="confimeUp()">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</el-container>

</template>
<script setup>
import { ref,computed} from 'vue'
import { Search } from '@element-plus/icons-vue'
import blank from '../components/blank.vue'
import { Teacher } from '../pojo/Teacher.js';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { upTeacherInfo } from "../api/teacher"
import {ElMessage} from "element-plus";


const store = useStore();

const router = useRouter()

const activeName = ref('教学计划');
const teacherDialog = ref(false);

const activeIndex = ref('2');
var loginIdentity = computed(() => store.state.identity);

const teacherform = ref({
  id: '',
  username:'',
  realname:'',
  age:'',
  gender:'',
  phone:'',
  teachingExperience:''
})

const handleSelect = (index) => {
  if(index == 1){
    router.push('/');
  }
}

const upTeacher = () => {
  teacherform.value.id = teacher.value.id;
  teacherform.value.username = teacher.value.username;
  teacherform.value.realname = teacher.value.realname;
  teacherform.value.age = teacher.value.age;
  teacherform.value.gender = teacher.value.gender;
  teacherform.value.phone= teacher.value.phone;
  teacherform.value.teachingExperience = teacher.value.teachingExperience;
  teacherDialog.value = true;
}

const teacher = computed(() => store.state.user);

const confimeUp = () => {
  upTeacherInfo(teacherform.value).then((res) => {
    // 返回修改的状态
    console.log(res);
    if(res.code == 200){
      ElMessage({
        message: '修改成功',
        type: 'success'
      });
      teacherDialog.value = false;
      store.commit('updateUserInfo', teacherform.value); // 假设服务器响应中 user 数据在 res.data.user 中 
      // 刷新页面
    }else{
      ElMessage({
        message: '修改失败',
        type: 'error'
      });
    }
  })
}

// 退出登录
const logout = () => {
  store.commit('upLoginStatus', false); // 假设服务器响应中 user 数据在 res.data.user 中 
  router.push('/');
}

const menuRef = ref(null); // 创建对菜单组件的引用


</script>

<style scoped>
.container{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  width: 40%;
  margin-left: 10%;
 }
 .medium{
  margin: 3.5% 2.5% 0 2.5%;
  width: 95%;
  height: 370px;
  background-color: #7b9edc;
  display: flex;
  /* border: 1px solid #333; 边框宽度和颜色 */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 阴影效果 */
 }
 .bottom{
  margin: 0 2.5% 2.5% 2.5%;
  width: 95%;
  background-color: #7b9edc;
  height: 450px;
 }
::v-deep .el-tabs {
  /* background-color: antiquewhite;*/
  width: 100%;
  height: 350px;
}
.blank{
  height: 280px;
  align-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: white;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); 设置阴影效果 */
}
.demo-tabs{
  height: 450px;
}
.mine {
  width: 75%;
  height: 320px;
  /* 设置子元素在主轴方向上居左对齐 */
  margin: 25px;
}

.mine text {
  margin: 0.7%;
  font-size: 17px;
}
</style>
