<template>
  <div class="container">
    <div class="header">
      <el-menu :default-active="activeIndex" ref="menuRef" id="container-menu" mode="horizontal" :ellipsis="false"
        @select="handleSelect">
        <el-menu-item index="1" style="margin: 0 20px;">首页</el-menu-item>
        <el-menu-item index="2" style="margin: 0 20px;">关于我们</el-menu-item>
        <div class="header">
          <!-- 设置class没有效果，可能是因为优先级不够，直接内嵌样式 -->
          <el-input placeholder="请输入您需要查找的赛事/课程/名师" style="width: 100%;padding-top: 10px;" size="large">
            <template #append>
              <el-button :icon="Search" />
            </template>
          </el-input>
        </div>
      </el-menu>
    </div>
    <div class="oursDiv">
      <div class="innerDiv">
        <div class="innerDiv_header">
          <p class="header_p">“爱数美”机构简介</p>
          <p class="header_details_p"> “爱数美”是一家坐落在某新一线城市的奥林匹克数学竞赛培训机构， 随着奥数竞赛在小学生中的普及和重视程度不断提高，本机构旨在增强学生的数学能力和竞赛技巧。</p>
          <div class="timeline">
            <div v-for="(item, index) in timelineData" :key="index" class="timeline-item"
              :class="{ 'timeline-item-right': index % 2 !== 0 }">
              <div class="timeline-content">
                <div class="timeline-time">{{ item.time }}</div>
                <div class="timeline-description">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { ElMessageBox } from 'element-plus'
import { gsap } from "gsap";
import { useRouter } from 'vue-router'

const activeIndex = ref('2');

const router = useRouter()

const handleSelect = (index) => {
  if (index == 1) {
    router.push('/');
  } else if (index == 2) {
    router.push('/ours');
  }
}

// 时间轴数据
const timelineData = ref([
  { time: '', description: '2020年，机构在广州成立' },
  { time: '', description: '2021年，机构斩获“最佳教育机构”奖项' },
  { time: '', description: '2024年，机构教学人数突破5000人' },
]);
</script>
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.header_p {
  font-size: 25px;
}

#container-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 97%;
  height: 65px;
  z-index: 2000;
  margin: 0px 1.5%;
}

.el-menu-item:hover {
  background-color: transparent;
  /* 设置悬浮时背景色为透明 */
  color: inherit;
  /* 保持文字颜色不变 */
}

.container {
  position: relative;
  width: 100%;
}

.header {
  width: 50%;
  height: 65px;
  margin-left: 10%;
  display: flex;
  /* 启用 Flexbox 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.header_p {
  font-size: 28px;
  margin: 20px 0;
  /* 移除默认的段落边距 */
}

.header_details_p {
  font-size: 20px;
  margin: 10px 120px;
}

.oursDiv {
  display: flex;
  /* 启用 Flexbox 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  width: 100%;
  /* 覆盖整个视口的宽度 */
  height: 92vh;
  /* 覆盖整个视口的高度 */

  margin: 0;
  /* 确保没有额外的边距 */
}

.innerDiv {
  width: 85%;
  /* 设置宽度为 80% */
  height: 90%;
  /* 设置高度为 80% */
  background-color: rgb(248, 242, 242);
  /* 为了可见性，设置一个背景色 */
}

.innerDiv_header {
  display: flex;
  /* 启用 Flexbox 布局 */
  flex-direction: column;
  /* 设置为垂直方向 */
  align-items: center;
  /* 水平居中 */
  height: 100%;
  /* 确保子元素能够在整个高度内居中 */
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 90px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 32%;
  bottom: 10%;
  width: 2px;
  background-color: gray;
  transform: translateX(-50%);
}

.timeline-item {
  display: flex;
  width: 60%;
  margin-bottom: 50px;
  margin-left: 20%;
}

.timeline-item-right {
  flex-direction: row-reverse;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  width: 43%;
  padding: 25px;
  background-color: #dfd6d6;
  border-radius: 8px;
  box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.1);
}

.timeline-time {
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-description {
  margin: 0;
}
</style>