// 所有user对象的接口调用
import request from '@/utils/request.js'

// 获取一个教师的所有信息
export const getTeacherInfo = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('teacher/getTeacherInfo', Data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// 更新老师信息
export const upTeacherInfo = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('teacher/updateTeacherInfo', Data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
