// 导入axios npm install axios
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useTokenStore } from '@/stores/token';

const baseURL = "/api"
const instance = axios.create({
  baseURL: baseURL
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config)=>{
    // 请求前回调
    // 添加token
    const tokenStore = useTokenStore();
    console.log("tokenStore",tokenStore);
    if(tokenStore.token){
      config.headers.authorization = tokenStore.token;
    }
    console.log("token",config);
    return config;
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  result => {
    console.log(result);
    if(result.data.code === 200){
      return result.data;
    }
    // ElMessage.error(result.data.msg?result.data.msg:"接口访问异常");
    // 异步操作转为失败
    return Promise.reject(result.data);
  },
  result => {
    alert("服务异常");
    // ElMessage.error(result.data.msg?result.data.msg:"发送请求服务异常");
    return Promise.reject(result);
  }
)

export default instance;
