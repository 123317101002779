import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import Door from '../views/Door.vue'
import Student from '../views/Student.vue'
import Teacher from '../views/Teacher.vue'
// import Test from '../views/test.vue'
// import Competition from '@/components/competition.vue'
import Ours from "../views/Ours.vue"

const routes = [
  {
    path: '/',
    name: 'main',
    component: Door,
  },
  {
    path: '/student',
    name: 'student',
    component: Student,
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: Teacher
  },
  {
    path: '/ours',
    name: 'Ours',
    component: Ours
  },
  {
    path: '/im',
    name: 'Im',
    component: () => import("../views/Im"),
  },  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
