import { createStore } from 'vuex'
import Student from '../pojo/Student'
import { getTeacherInfo } from '../api/teacher'
import Teacher from '../pojo/Teacher'
import Admin from '../pojo/Admin'

// 类似 Redux 中的建立状态树
const store = createStore({
  state() {
    return {
      isLogin: false, // 初始登录状态
      identity: '',
      user:{}, // 登录者身份信息
    };
  },
  mutations: {
    updateLoginStatus(state, { isLogin, identity, user }) {
      state.isLogin = isLogin;
      state.identity = identity;
      state.user = user; // 这里直接设置user，但注意user可能是不完整的
    },
    updateUserInfo(state, userInfo) {
      // 假设 userInfo 是一个包含用户新信息的对象
      const user = state.user.upUser(userInfo);
      state.user = user // 使用对象展开操作符合并新旧用户信息

    },
    upLoginStatus(state,isLogin){
      state.isLogin = isLogin;
    }
  },
  actions: {
    async updateLoginStatus({ commit }, payload) {
      const { isLogin, user } = payload;
      // 根据不同的身份执行不同的逻辑
      let loggedInUser;
      if (user.roleName === 'student') {
        const student = new Student(user.id);
        loggedInUser = await student.login(); // 假设login方法返回一个Promise，它resolve时包含完整的学生信息
      }else if (user.roleName === 'teacher'){
        const teacher = new Teacher(user.id);
        loggedInUser = await teacher.login(); // 假设Teacher构造函数足够用来初始化对象
      }else if (user.roleName === 'admin'){
        loggedInUser = new Admin(user.id,user.username,user.roleName);
        console.log(loggedInUser);
        //  = await admin.login(); // 假设Teacher构造函数足够用来初始化对象
      }

      // 提交mutation，更新状态
      commit('updateLoginStatus', {
        isLogin: isLogin,
        identity: user.roleName, // 假设user对象包含roleName属性
        user: loggedInUser,
      });
    },
  },
  getters: {
    loginStatus(state) {
      return state.isLogin;
    },
    identityStatus(state) {
      return state.identity;
    }
  }
})

export default store;

