<template>
  <div style="height: 600px;width: 420px; ">
    <!-- 右边内容 -->
    <el-tabs type="border-card">
      <el-tab-pane label="竞赛空间">
        <div class="room">
          <div class="room_content">
            <div v-for="(item, index) in items" :key="index" class="content-item">
              <span>{{ item.name + "：" }}</span>
              <span>{{ item.comment }}</span>
              <p class="room_content_p">{{ item.time }}</p>
            </div>
          </div>
          <div class="room_bottom">
            <div class="icon">📷</div>
            <el-input type="text" class="input" placeholder="我也说一句" />
            <el-button class="send-button">发送</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题">
        <div class="demo-collapse questionRoom">
          <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(question, index) in questionData" :key="index" :title="question.name" :name="String(index + 1)">
            <div>{{ question.detail }}</div>
          </el-collapse-item>
        </el-collapse>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const activeNames = ref(['0'])
const handleChange = (val) => {

  console.log(val)
}
const questionData = ref([
  {
    name:"关于竞赛的说明",
    detail:"必须在开始和结束期间进行报名，可以去官网查看名单确定是否报名成功，会发送信息提示"
  },
  {
    name:"竞赛注意事项",
    detail:"在竞赛开始的前几天会发短信进行通知，记住考试的地点和时间，超过十五分钟不准进入考场"
  },
])
const items = ref([
  {
    name: '刘六',
    comment: '这个竞赛的题目挺适合刚入门的学生进行训练',
    time: '2024-06-17 12:45'
  },
  {
    name: '赵赵',
    comment: '别忘了查看相关的解析视频，考完了不知道答案和没考一样！',
    time: '2024-06-17 12:45'
  },
  {
    name: '三三',
    comment: '保佑我得高分！',
    time: '2024-06-17 12:45'
  },
]);
</script>

<style scoped>
.room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 600px;
  border: 1px solid rgb(181, 178, 178);
  /* background-color: aqua; */
}

.questionRoom{
  height: 600px;
  overflow-y: auto;
}

.room_content {
  flex: 1;
  overflow-y: auto;
  /* 使内容超出时出现滚动条 */
  padding: 10px;
  /* 仅为了美观 */
}

.room_content_p {
  font-size: 12px;
  color: rgb(128, 128, 128);
}


.content-item {
  margin: 20px 10px;
  padding: 10px; /* 增加内边距，使内容更美观 */
  height: auto; /* 自动调整高度 */
  word-wrap: break-word; /* 允许单词换行 */
}

.room_bottom {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: space-between;
  /* 水平分布 */
  height: 60px;
  /* 你可以根据需要调整高度 */
  border-top: 1px solid rgb(128, 128, 128);
  background-color: rgb(240, 233, 233);
  /* 仅为了可见性 */
  padding: 0 10px;
  /* 内边距 */
}

.icon {
  font-size: 24px;
  /* 调整图标大小 */
  margin-left: 10px;
}

.input {
  flex: 1;
  /* 占据剩余空间 */
  margin: 0 30px;
  /* 左右内边距 */
  border-radius: 4px;
  /* 圆角 */
  border: 1px solid rgb(128, 128, 128);
}

.send-button {
  margin-right: 10px;
  padding: 5px 15px;
  /* 内边距 */
  border: none;
  /* 无边框 */
  background-color: #007bff;
  /* 按钮背景色 */
  color: white;
  /* 按钮文字颜色 */
  border-radius: 4px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标指针 */
}

.send-button:hover {
  background-color: #0056b3;
  /* 悬停效果 */
}
</style>
