import { getStudentInfo } from '../api/student'
import { getUser } from "@/api/function";

export class Admin {
  constructor(id,username,roleName) {
    this.id = id;
    this.username = username;
    this.roleName = roleName;
    this.status = "admin";
  }
}
export default Admin;
