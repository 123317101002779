// import User from "@/pojo/User";
import {defineStore} from "pinia";
import {ref} from "vue";

// 保存login登陆jwt令牌
// 需要两个参数，唯一性名字，函数内部定义状态所有内容 返回值：函数
export const useTokenStore = defineStore('token', () => {
    // 定义状态的内容
    // 响应式变量
    const token = ref('')
    // 函数,修改token的值
    const setToken = (newToken) => {
        token.value = newToken
    }

    const getToken = () => {
      return this.token
    }

    // 函数,移除token的值
    const removeToken = () => {
        token.value = ''
    }
    return {
        token, setToken, removeToken,getToken
    }
});



