<template>
  <div class="curseDemo">
    <ul v-infinite-scroll="load" class="infinite-list" style="overflow: auto;height: 780px">
      <li v-for="(course, index) in courseData" :key="index" class="infinite-list-item" @click="openDialog(course)">
        <img class="curseDemoImg" :src="course.image" />
      </li>
    </ul>
    <el-dialog title="课程" v-model="curseDialog" :close="clickClose()" width="760px" height="500px" class="dialog"
      align-center>
      <div>
        <div class="curseBox" style="border: 1px solid black;">
          <img src="../img/login.jpg" class="curseBoxImg">
          <div class="explain">
            <text>授课名称：{{ currentCourse.courseName }}</text>
            <text>授课老师：{{ currentCourse.teacherName }}</text>
            <text>开始时间：{{ currentCourse.startDate }}</text>
            <text>结束时间：{{ currentCourse.endDate }}</text>
            <text>课程介绍：{{ currentCourse.details }}</text>
            <el-button type="primary" style=" width: 200px; margin: 5% 25% 0 2%" plain
              @click="appCourse()">进入课程</el-button>
          </div>
          <div class="curseBoxLeft">
            <icon v-if="!open" @click="clickOpen()">
              <ArrowRight style="width: 4em; height: 3em;" />
            </icon>
            <icon v-if="open" @click="clickClose()">
              <ArrowLeft style="width: 4em; height: 3em;" />
            </icon>
          </div>
        </div>
        <div class="box">
          <!-- 右边内容 -->
          <courseDemo />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import { ElMessageBox } from 'element-plus'
import { gsap } from "gsap";
import courseDemo from './courseDemo.vue'
import { getCourse } from "../api/function"
import { useStore } from 'vuex';

const store = useStore();
const sideDialogVisible = ref(false);
const curseDialog = ref(false)

const open = ref(false);

const courseform = ref({
  id: '',
  studentId: '',
  courseName: '',
  registrationDate: '',
  status: '',
})

const currentCourse = ref({
  id: '',
  courseName: '',
  teacherName: '',
  startDate: '',
  endDate: '',
  image: '',
  details: ''
})

onMounted(() => {
  getCourse().then((res) => {
    courseData.value = res.data;
  })
});

const courseData = ref([])

const clickOpen = () => {
  open.value = true;
  gsap.fromTo(".dialog", { x: 0 }, { x: -300, duration: 1 });
  gsap.to(".box", { x: 220, y: -180, opacity: 1, zIndex: 1, duration: 1 })
}
const clickClose = () => {
  open.value = false;
  gsap.fromTo(".dialog", { x: -300 }, { x: 0, duration: 1 });
  gsap.to(".box", { opacity: 0, zIndex: 0 })
}

const load = () => {
  // courseData.value += 0;
}

const innerVisible = ref(false);

import {ElMessage} from 'element-plus'

const appCourse = () => {
  if(store.state.isLogin == false){
    ElMessage({
      message: '你还没登陆！',
      type: 'error'
    })
  }else{
    courseform.value.id = currentCourse.value.id;
    courseform.value.studentId = store.state.user.id;
    courseform.value.courseName = currentCourse.value.courseName;
    innerVisible.value = true;
  }
  
}

const openDialog = (item) => {
  currentCourse.value.id = item.id
  currentCourse.value.courseName = item.courseName;
  currentCourse.value.teacherName = item.teacherName;
  currentCourse.value.startDate = item.startDate;
  currentCourse.value.endDate = item.endDate;
  currentCourse.value.image = item.image;
  currentCourse.value.details = item.details;
  curseDialog.value = true;
}

</script>

<style scoped>
div {
  width: 95%;
  height: 100%;
  background-color: white;
  /* border: 1px solid rgb(128, 128, 128); */
  /* 设置边框为1像素宽的实线，并将颜色设置为灰色 */
}

.curseDemoImg {
  height: 100%;
  width: 100%;
}

.box {
  height: 600px;
  width: 380px;
  position: fixed;
  top: 20%;
  left: 75%;
  /* background-color: aqua; */
  /* transform: translateX(-100%); */
  bottom: 0;
  opacity: 0;
}

.infinite-list-item {
  display: inline-block;
  /* 使列表项以块级元素显示 */
  width: 310px;
  /* 设置方块的宽度 */
  height: 200px;
  /* 设置方块的高度 */
  /* background-color: #5ea4b1; */
  /* 设置方块的背景颜色 */
  margin: 5px 15px;
  /* 设置方块之间的间距 */
  text-align: center;
  /* 文字居中 */

}

.curseBox {
  display: flex;
  height: 400px;
  width: 690px;
  display: flex;
  align-items: center;
  /* 垂直居中 */
  position: relative;
  z-index: 2;
  border: 1px solid white;
}

.curseBoxImg {
  height: 400px;
  width: 350px;
}

.curseBoxLeft {
  height: auto;
  width: 60px;
  /* background-color: #99a9bf; */
  /* padding-top: 23%; */
  border: 1px solid white;
  /* padding-right: 5%; */
}

.explain {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 340px;
  border-top: 1px solid white;
}

.explain text {
  margin: 1.3%;
  font-size: 16px;
}

.curseDemo {
  width: 98%;
  /* height: 50%; */
  border: 1px white solid;
  /* margin: 0.5% 1%; */
  /* background-color: aquamarine; */
}
</style>
