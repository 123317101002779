import request from '@/utils/request.js'
// 登陆调用
export const login = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('/user/login' ,Data, {
        headers: {
            'Content-Type': 'application/json'
        },
    });
}

export const logoutUser = () => {
    return request.post('/user/logout');
}

// 资讯，用户，课程，竞赛模块的增加接口
export const addAllDate = (Data,currentName) => {
    console.log(Data);
    console.log(currentName+"现在");
    if(currentName == '资讯'){
        return request.post('resource/add' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == '用户'){
        return request.post('user/add' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == '课程'){
        return request.post('course/add' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == '竞赛'){
        return request.post('resource/add' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }


}

// 资讯，用户，课程，竞赛模块的修改接口
export const upAllDate = (Data,currentName) => {
    console.log(currentName);
    if(currentName == 0){
        return request.post('resource/update' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == 1){
        return request.post('user/update' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == 2){
        return request.post('course/update' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }else if(currentName == 3){
        return request.post('resource/update' ,Data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }


}

// 资讯，用户，课程，竞赛模块的删除接口
export const delAllDate = (Data,currentName) => {
    if(currentName == 0){
        return request.post('resource/delete' ,{params:{id:Data}});
    }else if(currentName == 1){
        return request.get('user/delete' ,{params:{id:Data}});
    }else if(currentName == 2){
        return request.get('course/delete' ,data,{params:{id:Data}});
    }else if(currentName == 3){
        return request.get('resource/delete' ,data, {params:{id:Data}});
    }
}


// 注册调用
export const register = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('student/register', Data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const getAllUser = () => {
    return request.get('user/list')
}

export const solution = () => {
    return request.get('resource/consultation')
}

export const getCourse = () => {
    return request.get('course/all')
}

export const getCompetition = () => {
    return request.get('resource/query')
}

// 获得登录者的身份信息
export const getUser = (Data) => {
    return request.get('user/info', Data);
}




