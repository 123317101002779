import { idText } from 'typescript';
import { getTeacherInfo } from '../api/teacher'
import {getStudentInfo} from "@/api/student";

export class Teacher {
  constructor(id) {
    this.id = id;
    this.status = "teacher"
  }
// 在这里调用接口函数
async login(){
  return getTeacherInfo().then((res) => {
    this.id = res.data.id;
      this.username = res.data.username;
      this.realname = res.data.realName;
      this.age = res.data.age;
      this.gender = res.data.gender;
      this.teachingExperience = res.data.teachingExperience;
      this.phone = res.data.phone;
    return this;
  })
}
upUser(dataForm){
  this.id = dataForm.id;
      this.username = dataForm.username;
      this.realname = dataForm.realname;
      this.age = dataForm.age;
      this.gender = dataForm.gender;
      this.teachingExperience = dataForm.teachingExperience;
      this.phone = dataForm.phone;
  return this;
}

}

export default Teacher;
