<!-- 竞赛组件 -->
<template>
  <div class="curseDemo">
    <ul v-infinite-scroll="load" class="infinite-list" style="overflow: auto;height: 780px">
      <li v-for="(course, index) in courseData" :key="index" class="infinite-list-item" @click="openDialog(course)">
        <img class="curseDemoImg" :src="course.image" />
      </li>
    </ul>
    <el-dialog title="竞赛" v-model="curseDialog" :close="clickClose()" width="760px" height="500px" class="dialog"
      align-center>
      <div>
        <div class="curseBox" style="border: 1px solid black;">
          <img :src="currentCourse.image" class="curseBoxImg">
          <div class="explain">
            <text>竞赛名称：{{ currentCourse.title }}</text>
            <text>竞赛种类：{{ currentCourse.category }}</text>
            <text>发布时间：{{ currentCourse.publishDate }}</text>
            <text>结束时间：{{ currentCourse.etime }}</text>
            <text>竞赛介绍：{{ currentCourse.content }}</text>
            <el-button type="primary" style=" width: 200px; margin: 5% 25% 0 2%" plain @click="appCom()">报名</el-button>
          </div>
          <div class="curseBoxLeft">
            <icon v-if="!open" @click="clickOpen()">
              <ArrowRight style="width: 4em; height: 3em;" />
            </icon>
            <icon v-if="open" @click="clickClose()">
              <ArrowLeft style="width: 4em; height: 3em;" />
            </icon>
          </div>
        </div>
        <div class="box">
          <!-- 右边内容 -->
          <competitionDemo />
        </div>
      </div>
      <el-dialog v-model="innerVisible" width="500" title="竞赛报名" style="height: 350px;" append-to-body align-center>
        <el-form :label-position="left" label-width="auto" :model="comform" style="max-width: 600px;">
          <el-form-item label="竞赛ID">
            <el-input v-model="comform.id" disabled />
          </el-form-item>
          <el-form-item label="学生ID">
            <el-input v-model="comform.studentId" disabled />
          </el-form-item>
          <el-form-item label="竞赛名称">
            <el-input v-model="comform.title" />
          </el-form-item>
          <el-form-item label="状态">
            <el-input v-model="comform.status" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer" style="height: 50px;width: 93%;">
            <el-button @click="innerVisible = false">取消</el-button>
            <el-button type="primary" @click="confimInner()">
              确定
            </el-button>
          </div>
        </template>
      </el-dialog>
    </el-dialog>
  </div>

</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import { ElMessageBox } from 'element-plus'
import { gsap } from "gsap";
import competitionDemo from './competitionDemo.vue'
import { getCompetition } from "../api/function"
import { useStore } from 'vuex';

const store = useStore();

const sideDialogVisible = ref(false);
const curseDialog = ref(false)

const open = ref(false);

onMounted(() => {
  getCompetition().then((res) => {
    console.log(res.data);
    courseData.value = res.data;
  })
});

const comform = ref({
  id: '',
  studentId: '',
  title: '',
  status: '已报名'
})

const currentCourse = ref({
  title: '',
  category: '',
  publishDate: '',
  etime: '',
  content: '',
  image: '',
})

const courseData = ref([])
import {ElMessage} from 'element-plus'
const innerVisible = ref(false);
const appCom = () => {
  if (store.state.isLogin == false) {
    ElMessage({
      message: '你还没登陆！',
      type: 'error'
    })
  }else{
    comform.value.id = currentCourse.value.id;
    comform.value.studentId = store.state.user.id;
    comform.value.title = currentCourse.value.title;
    innerVisible.value = true;
  }
}

// 竞赛报名
const confimInner = () => {
  // 调用竞赛报名接口
  const data = {
    id: comform.value.id,
    studentId: comform.value.studentId,
    title: comform.value.title,
    status: '已报名'
  }
  comRegis(data).then((res) => {
    // 成功了
    ElMessage({
      message: '报名成功！',
      type: 'success'
    })
    innerVisible.value = true;
  })
}

const clickOpen = () => {
  open.value = true;
  gsap.fromTo(".dialog", { x: 0 }, { x: -300, duration: 1 });
  gsap.to(".box", { x: 220, y: -180, opacity: 1, zIndex: 1, duration: 1 })
}
const clickClose = () => {
  open.value = false;
  gsap.fromTo(".dialog", { x: -300 }, { x: 0, duration: 1 });
  gsap.to(".box", { opacity: 0, zIndex: 0 })
}

const load = () => {
  // courseData.value += 0;
}

const openDialog = (item) => {
  currentCourse.value.id = item.id;
  currentCourse.value.title = item.title;
  currentCourse.value.category = item.category;
  currentCourse.value.publishDate = item.publishDate;
  currentCourse.value.etime = item.etime;
  currentCourse.value.image = item.image;
  currentCourse.value.content = item.content;
  curseDialog.value = true;
}

</script>

<style scoped>
div {
  width: 95%;
  height: 100%;
  background-color: white;
  /* border: 1px solid rgb(128, 128, 128); */
  /* 设置边框为1像素宽的实线，并将颜色设置为灰色 */
}

.curseDemoImg {
  height: 100%;
  width: 100%;
}

.box {
  height: 600px;
  width: 380px;
  position: fixed;
  top: 20%;
  left: 75%;
  /* background-color: aqua; */
  /* transform: translateX(-100%); */
  bottom: 0;
  opacity: 0;
}

.infinite-list-item {
  display: inline-block;
  /* 使列表项以块级元素显示 */
  width: 310px;
  /* 设置方块的宽度 */
  height: 200px;
  /* 设置方块的高度 */
  /* background-color: #5ea4b1; */
  /* 设置方块的背景颜色 */
  margin: 5px 15px;
  /* 设置方块之间的间距 */
  text-align: center;
  /* 文字居中 */

}

.curseBox {
  display: flex;
  height: 400px;
  width: 690px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  border: 1px solid white;
}

.curseBoxImg {
  height: 400px;
  width: 350px;
}

.curseBoxLeft {
  height: auto;
  width: 60px;
  /* background-color: #99a9bf; */
  padding-top: 23%;
  border: 1px solid white;
  /* padding-right: 5%; */
}

.explain {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 340px;
  border-top: 1px solid white;
  /* background-color: antiquewhite; */
}

.explain text {
  margin: 1.3%;
  font-size: 16px;
}

.curseDemo {
  width: 98%;
  /* height: 50%; */
  border: 1px white solid;
  /* margin: 0.5% 1%; */
  /* background-color: aquamarine; */
}
</style>
