<template>
  <div class="blank">暂时还没有相关记录</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.blank{
  height: 400px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: #7ad1e1;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); 设置阴影效果 */
}

</style>