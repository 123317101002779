import { createApp } from 'vue'
import Main from './Main.vue'
import router from './router'
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { gsap } from "gsap";
import { createPinia } from 'pinia';
import course from './components/course.vue'
import login from './components/login.vue'
import register from './components/register.vue'
import blank from './components/blank.vue'
import courseDemo from './components/courseDemo.vue'
import competition from './components/competition.vue'
import store from './store/index'
import axios from 'axios'
// import curse from './components/curse.vue'


const main = createApp(Main)
const pinia = createPinia();
// 在 app 的全局上下文中安装 gsap
main.config.globalProperties.$gsap = gsap
main.config.globalProperties.$axios = axios
main
.use(ElementPlus)
.use(router)
.use(pinia)
.use(store)
.mount('#app')
// main.component('curse',curse)
main.component('competition',competition)
main.component('course',course)
main.component('login',login)
main.component('register',register)
main.component('blank',blank)
main.component('courseDemo',courseDemo)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  main.component(key, component)
}


