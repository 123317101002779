<template>
  <el-table :data="tableData" style="width: 100%;height: 350px;overflow-y: auto;" >
    <el-table-column prop="name" label="举办方" width="180" />
    <el-table-column prop="activeName" label="竞赛" width="250" />
    <el-table-column prop="stime" label="开始时间" width="250" />
    <el-table-column prop="etime" label="结束时间" width="250"/>
    <el-table-column prop="details" label="详情"  /> 
  </el-table>
</template>

<script setup>
const tableData = [
{
    name: '广东竞赛机构',
    activeName:'三月奥数模拟竞赛', 
    stime: '2012-3月-22号',
    etime: '2012-3月-24号', 
    imgURL: '',
    details: ''
  },
  {
    name: '广东竞赛机构',
    activeName:'广东奥数竞赛',
    stime: '2012-12月-22号',
    etime: '2012-12月-24号', 
    imgURL: require("@/img/login.jpg"),
    details: ''
  },
  {
    name: '广东竞赛机构',
    activeName:'四月奥数模拟竞赛', 
    stime: '2012-4月-22号',
    etime: '2012-4月-24号', 
    imgURL: '',
    details: ''
  },
 
]
</script>
