<template>
  <div style="height: 600px;width: 420px; ">
    <!-- 右边内容 -->
    <el-tabs type="border-card">
      <el-tab-pane label="课程空间">
        <div class="room">
          <div class="room_content">
            <div v-for="(item, index) in items" :key="index" class="content-item">
              <span>{{ item.name + "：" }}</span>
              <span>{{ item.comment }}</span>
              <p class="room_content_p">{{ item.time }}</p>
            </div>
          </div>
          <div class="room_bottom">
            <div class="icon">📷</div>
            <el-input type="text" class="input" placeholder="我也说一句" />
            <el-button class="send-button">发送</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题">
        <div class="demo-collapse questionRoom">
          <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(question, index) in questionData" :key="index" :title="question.name" :name="String(index + 1)">
            <div>{{ question.detail }}</div>
          </el-collapse-item>
        </el-collapse>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const activeNames = ref(['0'])
const handleChange = (val) => {
  console.log(val)
}
const questionData = ref([
  {
    name:"关于课程人数的说明",
    detail:"本课程定期开课，每满200个人就会开启直播课程，可以在回放中查看相关的记录，上课不会对学生到课情况进行签到"
  },
  {
    name:"过时说明",
    detail:"如果已经报名了本课程，到达课程时间只会记录所有作业的最后一次状态，可以打开查看答案，但是无法再进行修改。如果没有报名课程，在课程结束前都可点击报名并且进入课程。"
  },
  {
    name:"上课说明",
    detail:"每节课大概45分钟左右，一次课程两节，中间会有十分钟的休息时间，直播的话可以实时和老师互动，录播根据自己的学习能力进行调整，可能还会随机抽学生回答问题，所以请保持基本的上课礼仪，不吃零食，正坐在桌子前，尽量准备好笔记本。"
  },
])
const items = ref([
  {
    name: '张三',
    comment: '这个老师讲的很清楚',
    time: '2024-06-17 12:45'
  },
  {
    name: '王武',
    comment: '还不错，就是时间有点短',
    time: '2024-06-17 12:45'
  },
  {
    name: '吴七七',
    comment: '布置的作业很有针对性',
    time: '2024-06-17 12:45'
  },
]);
</script>

<style scoped>
.room {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 600px;
  border: 1px solid rgb(181, 178, 178);
  /* background-color: aqua; */
}

.questionRoom{
  height: 600px;
  overflow-y: auto;
}

.room_content {
  flex: 1;
  overflow-y: auto;
  /* 使内容超出时出现滚动条 */
  padding: 10px;
  /* 仅为了美观 */
}

.room_content_p {
  font-size: 12px;
  color: gray;
}


.content-item {
  margin: 20px 10px;
  padding: 10px; /* 增加内边距，使内容更美观 */
  height: auto; /* 自动调整高度 */
  word-wrap: break-word; /* 允许单词换行 */
}

.room_bottom {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: space-between;
  /* 水平分布 */
  height: 60px;
  /* 你可以根据需要调整高度 */
  border-top: 1px solid gray;
  background-color: rgb(240, 233, 233);
  /* 仅为了可见性 */
  padding: 0 10px;
  /* 内边距 */
}

.icon {
  font-size: 24px;
  /* 调整图标大小 */
  margin-left: 10px;
}

.input {
  flex: 1;
  /* 占据剩余空间 */
  margin: 0 30px;
  /* 左右内边距 */
  border-radius: 4px;
  /* 圆角 */
  border: 1px solid gray;
}

.send-button {
  margin-right: 10px;
  padding: 5px 15px;
  /* 内边距 */
  border: none;
  /* 无边框 */
  background-color: #007bff;
  /* 按钮背景色 */
  color: white;
  /* 按钮文字颜色 */
  border-radius: 4px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标指针 */
}

.send-button:hover {
  background-color: #0056b3;
  /* 悬停效果 */
}
</style>
