// 所有user对象的接口调用
import request from '@/utils/request.js'

// 获取一个学生的所有信息
export const getStudentInfo = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('student/getStudentInfo', Data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

// 更新学生信息
export const upStudentInfo = (Data) => {
    // 借助urlSearchParams完成传递
    return request.post('student/updateStudentInfo', Data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
