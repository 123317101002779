<template>
  <el-form ref="ruleForm" :rules="rules" :model="loginForm" label-position="top"
           style="max-width: 400px;margin-top: 30px;">
    <el-form-item label="昵称" prop="userName">
      <el-input v-model="loginForm.username" placeholder="填写名字"/>
    </el-form-item>
    <el-form-item label="密码" style="margin-top: 20px;" prop="password">
      <el-input type="password" v-model="loginForm.password" placeholder="填写密码"/>
    </el-form-item>
    //选择身份
    <el-form-item label="身份" style="margin-top: 20px;" prop="identity">
      <el-select v-model="loginForm.identity" placeholder="请选择身份">
        <el-option label="学生" value="student"></el-option>
        <el-option label="教师" value="teacher"></el-option>
        <el-option label="管理员" value="admin"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item style="margin-top: 40px;">
      <el-button type="primary" @click="submitForm()">
        提交
      </el-button>
      <el-button @click="resetForm()">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {provide, ref, reactive, onMounted} from 'vue'
import {login, getCode,getUser} from "@/api/function.js"
import {useStore} from 'vuex';
import {useTokenStore} from '@/stores/token.js'

const store = useStore();

// 默认验证码图片
const loginForm = ref({
  username: 'studentTest',
  password: '111111',
  identity: ""
});


const User = {

}
// 获取验证码图片和答案的接口在这里调用
onMounted(() => {

});


import {ElMessage} from 'element-plus'
import router from "@/router";

const submitForm = () => {
  // 处理表单提交逻辑
  console.log('提交登录表单:', loginForm.value);

  // 将表格数据传输封装传递给接口
  const data = loginForm.value;

  login(data).then((res) => {
    console.log('登录接口返回数据:', res);

    const tokenStore = useTokenStore();
    tokenStore.setToken(res.data.token); // 设置token到本地存储

    User.id = res.data.id;
    User.username = res.data.username;
    User.roleName = loginForm.value.identity;
    store.dispatch('updateLoginStatus', {isLogin: true, user: User}); // 更新登陆状态并传递对象

    localStorage.setItem('user', JSON.stringify(res.data)); // 存储用户信息到本地
    localStorage.setItem('username', res.data.username); // 存储昵称到本地
    console.log('用户昵称信息:',localStorage.getItem('username'));
    ElMessage({
      message: '登录成功',
      type: 'success'
    })
  }).catch((error) => {
    console.log('登录失败:', error);  // 输出错误信息
  });
};


const rules = reactive({
  username: [
    {required: true, message: '请输入昵称', trigger: 'blur'}
  ],
  password: [
    {required: true, message: '请输入密码', trigger: 'blur'}
  ]
})


// 重置表单
const resetForm = () => {
  loginForm.value = {
    username: '',
    password: '',
    identity:""
  };
};

</script>

<style>
.check_div {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  /* background-color: aqua; */
  margin-top: 30px;
}

.check_span {
  width: 80px;
  /* background-color: aquamarine; */
}

.check_img {
  width: 80px;
  height: 50px;
  margin-right: 20px;

}
</style>
